<template>
	<div>
		<LoadingSpinner class="col-12" />
	</div>
</template>

<script>
import Contract from "@/mixins/Contract.js"

export default {
	name: 'CreateContract',
	mixins: [Contract],
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.contract_id = await this.addContractOnline();
			this.$router.push({name: "ContractFormv2", params: {contract_id: parseInt(this.contract_id)}})
		}
	},
	components: {
		LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}
</script>
